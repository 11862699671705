var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errore_caricamento)?_c('b-card',{attrs:{"border-variant":"danger"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"8"}},[_c('h4',{staticClass:"mt-0 mb-0 text-danger"},[_vm._v("Errore durante il caricamento delle informazioni iniziali")]),_c('p',[_vm._v("Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie")])]),_c('b-col',{staticClass:"text-center",attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'crm-customer-index' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Torna ai Clienti ")],1)],1)],1)],1):_vm._e(),(_vm.Caricato == false)?_c('div',{staticClass:"text-center my-3"},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"loading"}})],1):_vm._e(),(_vm.Caricato)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Dettagli")])]},proxy:true}],null,false,2106675683)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Ragione Sociale *","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.company_name),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "company_name", $$v)},expression:"campiform.billing.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,705466802)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nome *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.name),callback:function ($$v) {_vm.$set(_vm.campiform.user, "name", $$v)},expression:"campiform.user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3191535184)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cognome *","label-for":"Surname"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.user.surname),callback:function ($$v) {_vm.$set(_vm.campiform.user, "surname", $$v)},expression:"campiform.user.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,157517585)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email dell'utente","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.user.email),callback:function ($$v) {_vm.$set(_vm.campiform.user, "email", $$v)},expression:"campiform.user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2840911950)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Telefono","label-for":"telefono"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.phone),callback:function ($$v) {_vm.$set(_vm.campiform.user, "phone", $$v)},expression:"campiform.user.phone"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cellulare *","label-for":"Cellulare"}},[_c('validation-provider',{attrs:{"name":"Cellulare","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.mobile_phone),callback:function ($$v) {_vm.$set(_vm.campiform.user, "mobile_phone", $$v)},expression:"campiform.user.mobile_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4181104660)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Reseller *","label-for":"reseller_id"}},[_c('b-form-select',{attrs:{"options":_vm.options_usercrm_reseller},model:{value:(_vm.campiform.customer.reseller_id),callback:function ($$v) {_vm.$set(_vm.campiform.customer, "reseller_id", $$v)},expression:"campiform.customer.reseller_id"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Assistente *","label-for":"assistant_id"}},[_c('b-form-select',{attrs:{"options":_vm.options_usercrm_assistente},model:{value:(_vm.campiform.customer.assistant_id),callback:function ($$v) {_vm.$set(_vm.campiform.customer, "assistant_id", $$v)},expression:"campiform.customer.assistant_id"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"HR *","label-for":"hr_id"}},[_c('validation-provider',{attrs:{"name":"HR","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_usercrm_hr},model:{value:(_vm.campiform.customer.hr_id),callback:function ($$v) {_vm.$set(_vm.campiform.customer, "hr_id", $$v)},expression:"campiform.customer.hr_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,730726944)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sito web","label-for":"website"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.website),callback:function ($$v) {_vm.$set(_vm.campiform.user, "website", $$v)},expression:"campiform.user.website"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"textarea-note"}},[_vm._v("Note")]),_c('b-form-textarea',{attrs:{"id":"textarea-note","rows":"3"},model:{value:(_vm.campiform.customer.note),callback:function ($$v) {_vm.$set(_vm.campiform.customer, "note", $$v)},expression:"campiform.customer.note"}})],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"LockIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Fatturazione")])]},proxy:true}],null,false,3779487565)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nazione *","label-for":"country_id"}},[_c('validation-provider',{attrs:{"name":"Nazione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"autocomplete",attrs:{"suggestions":_vm.filteredOptions,"limit":8,"input-props":{id:'autosuggest__input',class:'form-control', placeholder:'cerca nazione', },"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.valoreDefaultNazione),callback:function ($$v) {_vm.valoreDefaultNazione=$$v},expression:"valoreDefaultNazione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,964929116)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Indirizzo *","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Indirizzo","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"","state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.billing.address),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "address", $$v)},expression:"campiform.billing.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3760786926)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cap *","label-for":"zip"}},[_c('validation-provider',{attrs:{"name":"Cap","rules":"required|digits:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.zip),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "zip", $$v)},expression:"campiform.billing.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2221293307)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Città *","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"Città","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.city),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "city", $$v)},expression:"campiform.billing.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2719263946)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Provincia *","label-for":"province_id"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_province},model:{value:(_vm.campiform.billing.province_id),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "province_id", $$v)},expression:"campiform.billing.province_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3637966621)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Partita IVA *","label-for":"vat_number"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"required|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.vat_number),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "vat_number", $$v)},expression:"campiform.billing.vat_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,524323047)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Codice fiscale","label-for":"fiscal_code"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.fiscal_code),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "fiscal_code", $$v)},expression:"campiform.billing.fiscal_code"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Pec","label-for":"pec"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.billing.pec),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "pec", $$v)},expression:"campiform.billing.pec"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Codice SDI","label-for":"dest_code"}},[_c('validation-provider',{attrs:{"name":"Codice SDI","rules":"length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.billing.dest_code),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "dest_code", $$v)},expression:"campiform.billing.dest_code"}})]}}],null,false,841558973)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Regime IVA","label-for":"vat_scheme_id"}},[_c('b-form-select',{attrs:{"options":_vm.options_regine_iva},model:{value:(_vm.campiform.billing.vat_scheme_id),callback:function ($$v) {_vm.$set(_vm.campiform.billing, "vat_scheme_id", $$v)},expression:"campiform.billing.vat_scheme_id"}})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.action_press},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[(_vm.action_press)?_c('div',[_vm._v("Salvaggio in corso "),_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}})],1):_c('div',[_vm._v("Salva")])])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }